export const NoteExample = [ // đường dùng
    '45 Võ Thị Sáu Q.1, Lầu 5',
    'Bệnh cần theo dõi sát',
    'Bệnh dễ tái phát',
    'Bệnh nặng theo dõi sát',
    'Chườm ấm',
    'Chườm lạnh',
    'Giữ vệ sinh',
    'Hạn chế thức khuya',
    'Khám nội khoa',
    'Khám nội thần kinh',
    'Khám nội tim mạch',
    'Khám tai mũi họng',
    'Không ăn đồ ngọt, cay, nóng',
    'Không dụi mắt',
    'Không tự ý ngưng, đổi thuốc',
    'KHÔNG TỰ Ý NGƯNG THUỐC NHỎ HẠ NHÃN ÁP',
    'Kiểm soát tốt đường huyết + huyết áp',
    'Kiêng rượu, bia, thuốc lá, thức khuya',
    'Mỗi loại thuốc nhỏ cách nhau 5-&gt;10 phút',
    'Mua bổ sung nếu hết thuốc',
    'Nằm nghỉ ngơi tại giường',
    'Rửa bờ mi bằng dầu Baby Johnson',
    'Tái khám đúng hẹn',
    'Theo hẹn phòng mổ',
    'Tiếp tục dùng thuốc cũ',
    'Tiếp tục nhỏ c.Vigadexa đủ 6 tuần theo toa trên phòng mổ',
    'Tránh bụi, nắng, gió, khói',
    'Tránh căng thẳng',
    'Tránh lây lan, theo dõi sát',
    'Tránh sử dụng thuốc có chứa Corticoid',
    'Tuân thủ điều trị',
    'Uống nhiều nước',
    'Vệ sinh bờ mi',
]