<template>
  <Container>
    <div class="d-block">
      <v-row>
        <!-- MedicineInfo -->
        <v-col lg="4" md="4" sm="12" sx="12">
          <v-card class="pa-0" elevation="0" outlined>
            <v-card-title class="d-flex align-center">
              <v-icon
                v-if="state == 'update'"
                class="mr-2"
                @click="backToCreate"
                color="primary"
                stlye="cursor: pointer"
              >
                mdi-arrow-left-bold-circle
              </v-icon>
              <span class="primary--text">
                {{
                  state == "create"
                    ? $t("examination.treatmentPlan.insertMedicationInfo")
                    : ""
                }}
                {{ state == "update" ? "Update an medication" : "" }}
              </span>
            </v-card-title>
            <v-card-text>
              <!-- search name medicine -->
              <v-row class="mt-1">
                <v-col cols="12">
                  <span class="font-weight-bold mb-1">{{
                    $t("examination.treatmentPlan.searchMedicine")
                  }}</span>
                  <v-combobox
                    class="w-50"
                    v-model="medicineItem"
                    dense
                    return-object
                    :items="list.items"
                    item-text="name"
                    :error="list.error"
                    :error_messages="list.error_messages"
                    ref="searchMedicine"
                    :disabled="state != 'create'"
                  ></v-combobox>
                </v-col>
              </v-row>
              <!-- Config -->
              <div class="d-block">
                <!-- Medicine route & How to use -->
                <v-row>
                  <v-col lg="6" md="6" sx="12" sm="12">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mb-1">
                        {{
                          $t("main.medicine.medicineTable.medicineRoute")
                        }}</span
                      >
                      <div class="d-flex align-center pa-0">
                        <v-select
                          class="pa-0"
                          v-model="data.medicineRoute"
                          :items="MedicineRoute"
                          item-text="vname"
                          item-value="id"
                          single-line
                          dense
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" sx="12" sm="12">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mb-1">
                        {{
                          $t("main.medicine.medicineTable.instructionsForUse")
                        }}</span
                      >
                      <div class="d-flex align-center pa-0">
                        <v-select
                          class="pa-0"
                          v-model="data.detailForUse"
                          :items="listDetailForUse"
                          single-line
                          dense
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <!-- Per of day & Detail Unit -->
                <v-row>
                  <!-- Per of day -->
                  <v-col lg="6" md="6" sx="12" sm="12">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mb-1">
                        {{ $t("main.medicine.medicineTable.timeDay") }}</span
                      >
                      <div class="d-flex align-center pa-0 mb-1">
                        <v-text-field
                          v-model="data.dosePerDay"
                          dense
                          class="pa-0"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>
                  <!-- Detail Unit -->
                  <v-col lg="6" md="6" sx="12" sm="12">
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bold mb-1">{{
                          $t("main.medicine.medicineTable.per")
                        }}</span>
                        <div class="d-flex">
                          <v-text-field
                            dense
                            class="mr-2 text-center pa-0"
                            v-model="data.amountPerDose"
                            required
                          ></v-text-field>
                          <v-select
                            dense
                            v-model="data.unitType"
                            :items="MedicineUnit"
                            item-text="vname"
                            item-value="id"
                            :label="$t('examination.treatmentPlan.unitType')"
                            class="ml-2 pa-0"
                            single-line
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <!-- How long & amount buying -->
                <v-row>
                  <!-- How long -->
                  <v-col lg="6" md="6" sx="12" sm="12">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mb-1">
                        {{ $t("main.medicine.medicineTable.in") }}</span
                      >
                      <div class="d-flex">
                        <v-text-field
                          dense
                          class="mr-2 text-center"
                          v-model="data.amountTime"
                          required
                        ></v-text-field>
                        <v-select
                          v-model="data.timeUnit"
                          class="ml-2"
                          :items="convertLang(listTime)"
                          item-text="name"
                          item-value="value"
                          dense
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                  <!-- How long -->
                  <v-col lg="6" md="6" sx="12" sm="12">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mb-1">{{
                        $t("main.medicine.medicineTable.per")
                      }}</span>
                      <div class="d-flex">
                        <v-text-field
                          dense
                          class="mr-2 text-center"
                          v-model="data.totalBuyingNumber"
                          required
                        ></v-text-field>
                        <v-select
                          v-model="data.buyingUnit"
                          :items="MedicineBuyingUnit"
                          item-text="vname"
                          item-value="id"
                          :label="$t('examination.treatmentPlan.unitType')"
                          class="ml-2"
                          single-line
                          dense
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <!-- Instruction -->
              <div class="d-block">
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold mb-1">
                        {{ $t("examination.treatmentPlan.instruction") }}</span
                      >
                      <div class="d-block">
                        <v-textarea
                          rows="2"
                          :placeholder="
                            $t('examination.treatmentPlan.instructionType')
                          "
                          v-model="data.instruction"
                        ></v-textarea>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!-- Action -->
              <div class="d-flex mt-3">
                <template v-if="state == 'create'">
                  <v-btn
                    @click="addMedication"
                    small
                    color="#007bff"
                    class="mr-1 white--text"
                  >
                    {{ $t("common.Save") }}
                  </v-btn>
                </template>
                <template v-if="state == 'update'">
                  <v-btn
                    @click="updateMedication"
                    small
                    color="#007bff"
                    class="mr-1 white--text"
                  >
                    {{ $t("common.update") }}
                  </v-btn>
                  <v-btn
                    small
                    color="#ffc107"
                    class="mr-1 white--text"
                    @click="backToCreate"
                  >
                    {{ $t("common.cancel") }}
                  </v-btn>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- List Medicine  -->
        <v-col lg="8" md="8" sm="12" sx="12">
          <ListMedicationComponent
            v-bind="$props"
            ref="ListMedicationComponent"
            @edit-medication="editMedication"
            @back-to-create="backToCreate"
          />
        </v-col>
      </v-row>

      <!-- config for medicine  -->

      <!-- Medicine -->
    </div>
  </Container>
</template>

<script>
import MedicationService from "@/services/medication";
import MedicineService from "@/services/medicine";
import i18n from "@/plugins/i18n";

import {
  MedicineRoute,
  MedicineUnit,
  MedicineBuyingUnit,
  ConfigMedForRoute,
  AllowPlusMedicineUnit,
} from "@/components/common/Medication/config";
import { Container } from "./css/style";
import { awaitAll } from "@/plugins/helper";

import ListMedicationComponent from "./ListMedicationRegistered";
import _ from "lodash";

export default {
  name: "StorageMedicineComponent",

  components: {
    Container,
    ListMedicationComponent,
  },
  props: {
    episodeRecordID: {
      type: String,
    },
  },
  watch: {
    medicineItem() {
      if (this.state == "create") {
        this.parseDataFromCombobox();
      }
    },
    "data.medicineRoute": {
      deep: true,
      handler() {
        if (this.data.medicineRoute != null) {
          let detailForUse = ConfigMedForRoute[this.data.medicineRoute];
          this.listDetailForUse = detailForUse;
          this.data.detailForUse = this.listDetailForUse[0]; // auto focus first element
          this.parseNote();
        } else {
          this.listDetailForUse = [];
          this.data.detailForUse = 1;
        }
      },
    },
    "data.detailForUse": {
      deep: true,
      handler() {
        this.parseNote();
      },
    },
    "data.dosePerDay": {
      deep: true,
      handler() {
        this.calculateTotalBuyingUnit();
        this.parseNote();
      },
    },
    "data.amountPerDose": {
      deep: true,
      handler() {
        this.calculateTotalBuyingUnit();
        this.parseNote();
      },
    },
    "data.unitType": {
      deep: true,
      handler() {
        this.calculateTotalBuyingUnit();
        this.parseNote();
      },
    },
    "data.timeUnit": {
      deep: true,
      handler() {
        this.parseTimeForUsing();
        this.calculateTotalBuyingUnit();
        this.parseNote();
      },
    },
    "data.amountTime": {
      deep: true,
      handler() {
        this.parseTimeForUsing();
        this.calculateTotalBuyingUnit();
        this.parseNote();
      },
    },
    "data.buyingUnit": {
      deep: true,
      handler() {
        this.calculateTotalBuyingUnit();
      },
    },
  },
  data: () => ({
    state: "create",
    medicineItem: {
      medicineID: null,
      name: null,
      description: null,
      defaultMedRoute: null,
      defaultUsageNum: null,
      unitType: null,
      defaultDosePerDay: null,
      defaultAmountPerDose: null,
      defaultBuyingNumber: null,
      defaultBuyingUnit: null,
      statusID: null,
      instruction: null,
    },

    list: {
      items: [],
      totals: 0,
      error: false,
      error_message: "",
    },

    listDetailForUse: [],

    data: {
      medicineID: "",
      note: "",
      detailForUse: 1,
      medicineRoute: null, // đường dùng
      dosePerDay: null, // só lần trong ngày
      amountPerDose: null, // mỗi lần
      unitType: null, // vien, giọt, miếng
      amountTime: 1, // keo dài bao lâu , default 1 days
      totalDays: 1,
      totalBuyingNumber: null, // số lượng mua
      buyingUnit: null, // đơn vi mua
      timeUnit: 1, // days|weeks|months
      instruction: "",
    },
    MedicineRoute,
    MedicineUnit,
    MedicineBuyingUnit,

    timeUsing: {
      days: 1,
      weeks: 2,
      months: 3,
    },
    listTime: [],
  }),

  created() {
    let entryTime = Object.entries(this.timeUsing);
    entryTime.forEach(([key, value]) => {
      let data = {
        text: key,
        value,
      };
      this.listTime.push(data);
    });

    // search all medicine
    this.searchMedicine("");
  },

  mounted() {
    this.$refs.ListMedicationComponent.searchMedicationItem();
  },
  methods: {
    async addMedication() {
      var creationResult = await MedicationService.create(
        this.episodeRecordID,
        this.data
      );
      if (creationResult.error) {
        this.$toast.error("Can note insert medicine!");
        return;
      }

      // Await finish load list for get last value
      await awaitAll([
        this.$refs.ListMedicationComponent.searchMedicationItem(),
      ]);

      this.$refs.ListMedicationComponent.scrollToEnd();
      // refresh data
      this.refreshData();
      // focus to searching medicine input
      this.$refs.searchMedicine.focus();
    },

    async searchMedicine(e) {
      var result = await MedicineService.search(e, 1, -1); // keyseach, page = 1, limit = -1 <--> unlimited
      if (result.error) {
        this.list.error_message = "Can not search! something went wrong!";
        this.list.error = true;
        return;
      }
      this.list = {
        items: result.items,
        totals: result.totals,
        error: false,
        error_message: "",
      };
    },
    parseDataFromCombobox() {
      this.data = {
        ...this.data,
        medicineID: this.medicineItem.medicineID,
        medicineRoute: this.medicineItem.defaultMedRoute,
        dosePerDay: this.medicineItem.defaultDosePerDay,
        unitType: this.medicineItem.unitType,
        amountPerDose: this.medicineItem.defaultUsageNum,
        totalBuyingNumber: this.medicineItem.defaultBuyingNumber,
        buyingUnit: this.medicineItem.defaultBuyingUnit,
      };
    },
    parseTimeForUsing() {
      let total = parseInt(this.data.amountTime);
      let timeUnit = parseInt(this.data.timeUnit);
      switch (timeUnit) {
        case this.timeUsing.days:
          this.data.totalDays = total;
          break;
        case this.timeUsing.weeks:
          this.data.totalDays = total * 7; // week
          break;
        case this.timeUsing.months:
          this.data.totalDays = total * 31; // month
          break;
        default:
          break;
      }
    },
    calculateTotalBuyingUnit() {
      let medUnit = parseInt(this.data.unitType);
      let totalDays = parseInt(this.data.totalDays);
      let amountPerDose = parseInt(this.data.amountPerDose);
      let dosePerDay = parseInt(this.data.dosePerDay);

      if (AllowPlusMedicineUnit.includes(medUnit)) {
        let totals = amountPerDose * dosePerDay * totalDays;
        if (this.state == "create") {
          this.data.totalBuyingNumber = totals;
        }
      } else {
        if (this.state == "create") {
          this.data.totalBuyingNumber = 1;
        }
      }
    },

    parseNote() {
      let medicineRoute, unitDesc, detailForUse, resultNote;
      if (this.data.medicineRoute != null) {
        medicineRoute = _.find(MedicineRoute, { id: this.data.medicineRoute })
          .vname;
        unitDesc = _.find(MedicineUnit, { id: this.data.unitType }).vname;
        detailForUse = this.data.detailForUse;
        // parse Note
        resultNote = `${medicineRoute} ${detailForUse}, ngày ${this.data.dosePerDay} lần, mỗi lần ${this.data.amountPerDose} ${unitDesc}, trong ${this.data.totalDays} ngày`;
        this.data.note = resultNote;
      }
    },
    editMedication(itemMedication) {
      console.log(itemMedication);
      if (typeof itemMedication != "undefined" && itemMedication != null) {
        let {
          medicationID,
          medicineID,
          note,
          medicineRoute,
          dosePerDay,
          unitType,
          amountPerDose,
          totalBuyingNumber,
          buyingUnit,
          instruction,
          daysCount,
        } = itemMedication;
        let findMedicine = this.list.items.find(
          (i) => i.medicineID == medicineID
        );
        if (typeof findMedicine != "undefined") {
          this.medicineItem = findMedicine;
          this.data = {
            ...this.data,
            medicationID: medicationID,
            medicineID: medicineID,
            totalDays: daysCount,
            amountTime: daysCount,
            note,
            medicineRoute,
            dosePerDay,
            unitType,
            amountPerDose,
            totalBuyingNumber,
            buyingUnit,
            instruction,
          };
          //      medicineID: "",
          // note: "",
          // detailForUse: 1,
          // medicineRoute: null, // đường dùng
          // dosePerDay: null, // só lần trong ngày
          // amountPerDose: null, // mỗi lần
          // unitType: null, // vien, giọt, miếng
          // amountTime: 1, // keo dài bao lâu , default 1 days
          // totalDays: 1,
          // totalBuyingNumber: null, // số lượng mua
          // buyingUnit: null, // đơn vi mua
          // timeUnit: 1, // days|weeks|months
          // instruction:
          this.state = "update";
          console.log(this.data);
        }
      }
    },
    refreshData() {
      this.data = {
        medicineID: "",
        note: "",
        detailForUse: 1,
        medicineRoute: null, // đường dùng
        dosePerDay: null, // só lần trong ngày
        amountPerDose: null, // mỗi lần
        unitType: null, // vien, giọt, miếng
        amountTime: 1, // keo dài bao lâu , default 1 days
        totalDays: 1,
        totalBuyingNumber: null, // số lượng mua
        buyingUnit: null, // đơn vi mua
        timeUnit: 1, // days|weeks|months
        instruction: "",
      };
      this.medicineItem = {
        medicineID: null,
        name: null,
        description: null,
        defaultMedRoute: null,
        defaultUsageNum: null,
        unitType: null,
        defaultDosePerDay: null,
        defaultAmountPerDose: null,
        defaultBuyingNumber: null,
        defaultBuyingUnit: null,
        statusID: null,
        instruction: null,
      };
    },
    backToCreate() {
      this.state = "create";
      this.refreshData();
      this.$refs.ListMedicationComponent.cancelUpdating();
    },
    // update medication
    async updateMedication() {
      console.log(this.data);
      let { medicationID } = this.data;

      var updateMedication = await MedicationService.update(
        medicationID,
        this.data
      );
      if (updateMedication.error) {
        this.$toast.error(updateMedication.message);
        return;
      }
      this.refreshData();
      this.state = "create";
      // call ref to update list medication on the right
      this.$refs.ListMedicationComponent.searchMedicationItem();
    },

    convertLang(text) {
      console.log(text);
      var val = text.map((i) => ({
        ...i,
        name:
          i.text == "days"
            ? i18n.t("main.medicine.medicineTable.days")
            : i.text == "weeks"
            ? i18n.t("main.medicine.medicineTable.weekss")
            : i18n.t("main.medicine.medicineTable.months"),
      }));
      return val;
    },
  },
};
</script>

<style>
</style>