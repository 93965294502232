<template>
  <div class="d-block w-100">
    <v-card class="pa-0" elevation="0" outlined>
      <v-card-title>
        <div class="d-flex justify-space-between align-center w-100">
          <span class="d-flex align-center">
            <v-icon class="mr-1">mdi-file-document-outline</v-icon>
            {{ $t("examination.treatmentPlan.medicinePrescription") }}
            <b class="ml-2 text-body-1">
              [{{ $t("examination.treatmentPlan.total") }}:
              <b class="primary--text">{{ this.medicationData.list.length }}</b
              >]
            </b>
          </span>
          <div class="d-flex">
            <!-- Load old form that storaged -->
            <div style="margin: 10px">
              <v-edit-dialog
                v-if="this.listRegisterMedicine.length > 0"
                persistent
                @save="loadOldFormMedicine"
                @cancel="closeLoadOldFormMedicine"
                large
              >
                <v-btn icon>
                  <v-icon small>mdi-reload</v-icon>
                </v-btn>
                <template v-slot:input>
                  <div class="mt-4 title">
                    {{ $t("examination.treatmentPlan.sampleListMedication") }}
                  </div>
                  <v-select
                    v-model="oldItemFormMedicine"
                    :items="listRegisterMedicine"
                    item-text="name"
                    return-object
                  ></v-select>
                </template>
              </v-edit-dialog>
            </div>
            <!-- Save medication on storage -->
            <div style="margin: 10px" v-if="medicationData.list.length > 0">
              <v-edit-dialog
                large
                persistent
                @save="saveListMedicine"
                @cancel="cancelStore"
              >
                <!-- Save Medication -->
                <v-btn icon>
                  <v-icon color="#3bae55" small> mdi-file-plus-outline </v-icon>
                </v-btn>
                <template v-slot:input>
                  <div class="mt-4 title">Create a new sample prescription</div>
                  <v-text-field
                    :rules="[(v) => !!v || 'Input is required!']"
                    v-model="newMedicationStorage.name"
                    placeholder="Name"
                    single-line
                    counter
                    autofocus
                    required
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </div>
            <!-- Printing -->
            <!-- <v-btn icon
              @click="printMedicinePrescription"
            >
              <v-icon color="blue" small>
                mdi-printer
              </v-icon>
            </v-btn> -->
            <GeneralPrint
              :childrensMenu="childrensMenu"
              :optionPrint="OptionPrint.Horizontal"
              :patientInfo="this.patientInfo"
              :listWantToPrint="listWantToPrint"
              :justIcon="justIcon"
            >
              <template #btnPrint>
                <v-btn icon>
                  <v-icon color="blue" small> mdi-printer </v-icon>
                </v-btn>
              </template>
            </GeneralPrint>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-sheet>
          <!-- Load current list medicine -->
          <template v-if="!oldForm.state">
            <!-- Load medicine from database -->
            <template v-if="!medicationData.loading">
              <v-list
                v-if="medicationData.list.length > 0"
                dense
                subheader
                id="fit-medicine-list"
                class="fit-medicine-list"
              >
                <v-list-item
                  dense
                  two-line
                  v-for="(item, index) in medicationData.list"
                  :key="index"
                  class="medicineItem"
                  :class="{ updateMedicineItem: item.update }"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1">
                      <span class="mr-1">{{ index + 1 }}/</span>
                      <span class="ml-1">{{ item.medicineName }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="mt-2 text-subtitle-2">{{
                      item.note
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="mt-2 text-subtitle-2"
                      v-if="item.instruction != ''"
                    >
                      <b class="font-weight-black text-decoration-underline"
                        >Ins:</b
                      >
                      {{ item.instruction }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text class="mt-1 mb-1">
                      <b class="text-subtitle-2 font-weight-bold">
                        <span class="mr-1">{{ item.totalBuyingNumber }}</span>
                        <span>{{ medicineRouteName(item.buyingUnit) }}</span>
                      </b>
                    </v-list-item-action-text>
                    <div class="d-block">
                      <!-- <v-btn
                        x-small
                        color="blue"
                        icon
                        >
                          <v-icon>
                            mdi-file-edit
                          </v-icon>
                        </v-btn> -->
                      <v-btn
                        v-if="!item.update"
                        x-small
                        color="primary"
                        icon
                        @click="editMedication(item.medicationID)"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!item.update"
                        x-small
                        color="red"
                        icon
                        @click="removeMedication(item.medicationID)"
                      >
                        <v-icon> mdi-window-close </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <p v-else class="red--text">
                {{ $t("examination.treatmentPlan.emptyMed") }}
              </p>
            </template>
            <template v-else>
              <div
                class="d-flex align-center justify-center w-100 pa-5"
                style="max-height: 200px"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </template>

          <!-- Load old list medicine -->
          <template v-if="oldForm.state && oldForm.list.length > 0">
            <span class="text-subtitle-1 ma-3"
              >Old Form: <b class="blue--text">[{{ oldForm.name }}]</b></span
            >
            <v-list class="mt-2" dense subheader>
              <v-list-item
                dense
                two-line
                v-for="(item, index) in oldForm.list"
                :key="index"
                class="medicineItem"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1">
                    <span class="mr-1">{{ index + 1 }}/</span>
                    <span class="ml-1">{{ item.medicineName }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-2 text-subtitle-2">{{
                    item.note
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="mt-2 text-subtitle-2"
                    v-if="item.instruction != ''"
                  >
                    <b class="font-weight-black text-decoration-underline"
                      >Ins:</b
                    >
                    {{ item.instruction }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="mt-1 mb-1">
                    <b class="text-subtitle-2 font-weight-bold">
                      <span class="mr-1">{{ item.totalBuyingNumber }}</span>
                      <span>{{ medicineRouteName(item.buyingUnit) }}</span>
                    </b>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <div class="d-block mt-1 ml-3">
              <v-radio-group v-model="rewrite" row>
                <v-radio
                  :value="true"
                  label="Create new medicine prescription"
                ></v-radio>
                <v-radio :value="false" label="Add more medicine"></v-radio>
              </v-radio-group>
            </div>
          </template>
        </v-sheet>
      </v-card-text>

      <v-card-text v-if="!oldForm.state">
        <div class="d-flex flex-column">
          <span class="text-subtitle-1 font-weight-bold">
            <v-icon class="mr-1">mdi-message-text-outline</v-icon>
            {{ $t("examination.treatmentPlan.medicationNote") }}
          </span>
          <div class="d-block mt-2">
            <v-combobox
              dense
              outlined
              class="w-100"
              v-model="medicationNote"
              item-text="name"
              item-value="name"
              :items="noteExamples"
              multiple
              ref="combobox"
              return-object
              chips
              small-chips
            ></v-combobox>
            <v-btn
              small
              class="font-white fit-button"
              color="#007bff"
              @click="saveMedicationNote"
            >
              {{ $t("common.Save") }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <div class="d-flex mt-1 justify-end pa-2" v-if="oldForm.state">
        <v-btn
          @click="saveAllOldListMedicine"
          small
          class="white--text mr-1"
          color="#007bff"
        >
          Save this list
        </v-btn>
        <v-btn
          @click="oldForm = { state: false, list: [], name: '' }"
          small
          class="white--text ml-1"
          color="#ffc107"
        >
          {{ $t("common.cancel") }}</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import GeneralPrint from "@/components/prescription/GeneralPrint";
import { OptionPrint } from "@/plugins/constant";
import { NoteExample } from "./noteExample";
import { mapState, mapMutations } from "vuex";
import _ from "lodash";
import MedicationService from "@/services/medication";
import EpisodeRecordService from "@/services/episodeRecord";
import i18n from "@/plugins/i18n";

import { MedicineBuyingUnit } from "./config";

export default {
  name: "ListMedicationRegisteredComponent",
  components: {
    GeneralPrint,
  },
  props: {
    episodeRecordID: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState("Medication", ["listRegisterMedicine"]),
  },
  mounted() {
    // check the local storage that having the data or not
    if (this.listRegisterMedicine.length > 0) {
      this.oldItemFormMedicine = this.listRegisterMedicine[0];
    }
  },
  watch: {
    listRegisterMedicine() {
      if (this.listRegisterMedicine.length > 0) {
        console.log("hey yeah");
        this.oldItemFormMedicine = this.listRegisterMedicine[0]; // get first as a default
      }
    },
  },
  data: () => ({
    OptionPrint,
    patientInfo: {
      episodeRecordID: "",
      patientID: "",
    },
    childrensMenu: false,
    justIcon: true,
    listWantToPrint: ["medicine-prescription"],
    oldForm: {
      list: [],
      state: false,
      name: "",
    },
    oldItemFormMedicine: {},
    rewrite: true,

    medicationData: {
      list: [],
      loading: false,
      totals: 0,
      error: false,
      error_message: "",
    },

    newMedicationStorage: {
      name: "",
      listMedicine: [],
    },
    medicationNote: "",
    noteExamples: NoteExample,
  }),
  async created() {
    this.getMedicationNote();
    this.patientInfo.episodeRecordID = this.episodeRecordID;
    this.patientInfo.patientID = this.$route.params.patientId;
    console.log(this.noteExample);
  },
  methods: {
    scrollToEnd: function () {
      var container = this.$el.querySelector("#fit-medicine-list");
      container.scrollTop = container.scrollHeight;
    },
    async searchMedicationItem() {
      this.medicationData.loading = true;
      var resultData = await MedicationService.search(
        this.episodeRecordID,
        "",
        1,
        -1
      );
      if (resultData.error) {
        this.medicationData = {
          loading: false,
          list: [],
          error: true,
          error_message: resultData.message,
        };
        return;
      }
      this.medicationData = {
        list: resultData.items,
        totals: resultData.totals,
        error: false,
        error_message: "",
      };

      this.medicationData.list = this.medicationData.list.map((i) => {
        i.update = false;
        return i;
      });
      this.refreshOldMedication();
    },

    medicineRouteName(buyingUnitID) {
      let buyingUnitRecord = _.find(MedicineBuyingUnit, { id: buyingUnitID });
      if (buyingUnitRecord == null) {
        return "";
      }
      return buyingUnitRecord.vname;
    },
    editMedication(medicationID) {
      let medicationItem = this.medicationData.list.find(
        (i) => i.medicationID == medicationID
      );

      if (typeof medicationItem != "undefined") {
        this.medicationData.list = this.medicationData.list.map((i) => {
          i.update = i.medicationID == medicationID ? true : false;
          return i;
        });
        this.$emit("edit-medication", medicationItem);
      }
    },
    cancelUpdating() {
      this.medicationData.list = this.medicationData.list.map((i) => {
        i.update = false;
        return i;
      });
    },

    async removeMedication(medicationID) {
      var removeMedication = await MedicationService.delete(medicationID);
      console.log(removeMedication);
      if (removeMedication.error) {
        this.medicationData.error = true;
        this.medicationData.error_message = "Can not remove Item! Try Again!";
        return;
      }
      // get list Again
      this.searchMedicationItem();
      this.$emit("back-to-create");
    },
    cancelStore() {
      this.newMedicationStorage = {
        name: "",
        listMedicine: [],
      };
    },

    saveListMedicine() {
      if (this.medicationData.list.length < 0) {
        this.$swal({
          icon: "error",
          title: "Oops",
          text: "List medicine is empty!",
        });
        return;
      }
      if (this.newMedicationStorage.name.length) {
        let listMedicineNeedStore = _.cloneDeep(this.medicationData.list);
        listMedicineNeedStore = listMedicineNeedStore.map((i) => {
          delete i["medicationID"];
          delete i["episodeRecordID"];
          delete i["dateCreated"];
          return i;
        });
        // console.log(listMedicineNeedStore)
        this.newMedicationStorage.listMedicine = listMedicineNeedStore;
        this.ADD_MORE_MEDICATION({ itemMedicine: this.newMedicationStorage });
      } else {
        this.$swal({
          icon: "error",
          title: "Oops",
          text: "You should put the name before creating",
        });
      }
    },
    loadOldFormMedicine() {
      let { listMedicine, name } = this.oldItemFormMedicine;
      this.oldForm = {
        state: true,
        list: listMedicine,
        name: name,
      };
    },
    closeLoadOldFormMedicine() {
      this.oldItemFormMedicine = {};
    },
    refreshOldMedication() {
      this.oldForm = {
        state: false,
        list: [],
      };
      this.rewrite = true;
    },
    async saveAllOldListMedicine() {
      var saveOldList = await MedicationService.createList(
        this.episodeRecordID,
        this.rewrite,
        this.oldForm.list
      );

      if (saveOldList.error) {
        this.$toast.error("Can not save a old list medicine!");
        return;
      }
      (this.medicationData = {
        list: saveOldList.items,
        loading: false,
        totals: saveOldList.totals,
        error: false,
        error_message: "",
      }),
        this.refreshOldMedication();
    },
    // printMedicinePrescription() {
    //   let patientID = this.$route.params.patientId;
    //   let episodeRecordID = this.$route.params.episodeRecordID;
    //   let srcLinkPrint = `/patient/${patientID}/prescription/${episodeRecordID}/medicine`;
    //   window.open(srcLinkPrint, "Glass Prescription", "width=800, height=500");
    //   //http://localhost:3003/patient/34108/prescription/130181/medicine
    // },
    async getMedicationNote() {
      let episodeRecordID = this.$route.params.episodeRecordID;
      var resultData = await EpisodeRecordService.getByID(episodeRecordID);
      if (resultData.error) {
        this.medicationData.error = true;
        this.medicationData.error_message = "Can not get medication note!";
        return;
      }
      this.medicationNote = resultData.medicationNote.split(",");
      console.log(this.medicationNote);
    },
    async saveMedicationNote() {
      this.$refs.combobox.blur();
      this.$nextTick(async () => {
        let episodeRecordID = this.$route.params.episodeRecordID;
        var medicationData = {
          medicationNote: this.medicationNote.toString(),
        };
        var resultData = await EpisodeRecordService.updateMedicationNote(
          episodeRecordID,
          medicationData
        );

        if (resultData.error) {
          this.$toast.error("Can not save a medication note!");
          return;
        }
        this.$toast.success(i18n.t("message.listMedicationNote"));
      });
    },
    ...mapMutations("Medication", ["ADD_MORE_MEDICATION"]),
  },
};
</script>

<style scoped>
.medicineItem:hover {
  cursor: pointer;
  background-color: #eaeaea;
}
.font-white {
  color: white;
}
.fit-button {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  right: 25px;
  bottom: 49px;
}
.fit-medicine-list {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
.updateMedicineItem {
  background-color: #a0d5ff;
}
</style>