<template>
  <Container>
    <v-card elevation="0">
      <v-card-title>
        <v-row class="pa-0" justify="space-between">
          <v-col class="pl-0 pr-0" lg="3" md="4" sm="12" sx="12">
            <span class="text-h5 font-weight-bold">{{
              $t("examination.treatmentPlan.medication")
            }}</span>
          </v-col>
          <v-col class="pl-0 pr-0" lg="3" md="4" sm="12" sx="12">
            <MedicineAction>
              <v-btn
                v-if="state.medication"
                @click="state = { medicine: true, medication: false }"
                small
                class="item"
              >
                {{ $t("examination.treatmentPlan.newMed") }}
              </v-btn>
              <v-btn
                v-if="state.medicine"
                @click="state = { medicine: false, medication: true }"
                small
                class="item"
              >
                {{ $t("examination.treatmentPlan.addMedication") }}
              </v-btn>
            </MedicineAction>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- Manage Medicine and Medication -->
      <v-card-text class="pa-0">
        <MedicineContainerComponent v-show="state.medicine" />
        <StorageMedication v-show="state.medication" v-bind="$props" />
      </v-card-text>
    </v-card>
  </Container>
</template>

<script>
import { Container, MedicineAction } from "./css/style";
import MedicineContainerComponent from "@/components/common/Medicine/MedicineContainer";
import StorageMedication from "./StorageMedication";
export default {
  name: "MedicationContainerComponent",
  components: {
    Container,
    MedicineAction,
    MedicineContainerComponent,
    StorageMedication,
  },
  props: {
    episodeRecordID: {
      type: String,
    },
  },
  data: () => ({
    state: {
      medication: true,
      medicine: false,
    },
  }),
};
</script>

<style>
</style>